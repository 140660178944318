import { Col, Container, Row } from "react-bootstrap";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import reactImg from "../assets/img/React.webp";
import htmlImg from "../assets/img/html.webp";
import cssImg from "../assets/img/css.webp";
import nodeImg from "../assets/img/nodeJS.webp";
import bootstrapImg from "../assets/img/bootstrap.webp";
import tailwindImg from "../assets/img/tailwind.webp";
import phpImg from "../assets/img/php.webp";
import sqlImg from "../assets/img/SQL.webp";
import fbImg from "../assets/img/facebook.webp";
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };

    return(
        <section className="skill" id="skills">
            <Container>
                <Row>
                    <Col>
                        <div className="skill-bx wow zoomIn">
                            <h2>Skills</h2>
                            <p> These are the skills I have acquired over the years </p>
                            <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                                <div className="item">
                                    <img src={htmlImg} alt="HTML-Logo" />
                                    <h5>HTML</h5>
                                </div>
                                <div className="item">
                                    <img src={cssImg} alt="CSS-Logo" />
                                    <h5>CSS</h5>
                                </div>
                                <div className="item">
                                    <img src={reactImg} alt="React-Logo" />
                                    <h5>React JS</h5>
                                </div>
                                <div className="item">
                                    <img src={nodeImg} alt="Node-Logo" />
                                    <h5>Node JS</h5>
                                </div>
                                <div className="item">
                                    <img src={bootstrapImg} alt="Bootstrap-Logo" />
                                    <h5>Bootstrap</h5>
                                </div>
                                <div className="item">
                                    <img src={tailwindImg} alt="Tailwind-Logo" />
                                    <h5>Tailwind CSS</h5>
                                </div>
                                <div className="item">
                                    <img src={phpImg} alt="PHP-Logo" />
                                    <h5>PHP</h5>
                                </div>
                                <div className="item">
                                    <img src={sqlImg} alt="SQL-Logo" />
                                    <h5>SQL</h5>
                                </div>
                                <div className="item">
                                    <img src={fbImg} alt="Facebook-Logo" />
                                    <h5>Facebook Marketing</h5>
                                </div>
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>
            <img className="background-image-left" src={colorSharp} alt=""/>
        </section>
    )
}
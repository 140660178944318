import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/logo2.png";
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/icons8-github.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';

export const Footer = () => {
    return(
        <footer className="footer">
            <Container>
                <Row className="align-items-center">
                    <Col size={12} sm={6}>
                        <img src={logo} alt="Logo" style={{width: "80px", height: "80px"}} />
                    </Col>
                    <Col size={12} sm={6} className="text-center text-sm-end">
                        <div className="social-icon">
                            <a href="https://linkedin.com/in/clayton-sampere"><img src={navIcon1} alt="LinkedIn" /></a>
                            <a href="https:.www.github.com/syn-127"><img src={navIcon2} alt="Facebook" /></a>
                            <a href="https://instagram.com/clayclaytheone"><img src={navIcon3} alt="Instagram" /></a>
                        </div>
                        <p>Copyright 2024. All Rights Reserved Clayton Sampere</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}